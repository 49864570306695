@import "https://cdn.jsdelivr.net/npm/boxicons@2.0.5/css/boxicons.min.css";

body {
    --primary: 25,91,255;
    --color: 44, 62, 80;
    align-items: flex-start;
    /*
    display: flex;
    justify-content: center;
    */
    height: 100vh;
    background: #f4f7f8;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    color: rgb(var(--color));
    width: 100%;
}
* {
    list-style: none;
    outline: none;
    padding: 0;
    margin: 0;
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
}
.container {
    position: fixed;
    top: calc(var(--vh, 1vh) * 100);
    width: 100%;
    height: calc(var(--vh, 1vh) * 50);
    background: #fff;
    border-radius: 30px 30px 0px 0px;
    padding-top: 40px;
    box-sizing: border-box;
    box-shadow: 0px 0px 30px 0px rgba(0,0,0,.05);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.container::after {
    content: '';
    left: 50%;
    top: -14px;
    width: 45px;
    height: 4px;
    background: rgba(0,0,0,.2);
    position: absolute;
    transform: translate(-50%);
    border-radius: 20px;
}
.container p {
    width: 70%;
    padding-top: 20px;
    font-size: .8rem;
    text-align: center;
    font-weight: 500;
    opacity: .7;
}
.container img {
    width: 75%;
}
.container button {
    padding: 12px 30px;
    background: rgb(232, 76, 79);
    color: #fff;
    border: 0px;
    margin-top: 20px;
    border-radius: 17px;
}
.container::before {
    content: '';
    left: 0px;
    bottom: -150px;
    width: 100%;
    height: 150px;
    background: #fff;
    position: absolute;
}
.bottom-navbar {
    position: fixed;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    background: #fff;
    border-radius: 30px 30px 0px 0px;
    padding: 10px 0px;
    box-shadow: 0px 0px 30px 0px rgba(0,0,0,.05);
}
.bottom-navbar button {
    width: 60px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0px;
    background: transparent;
    border-radius: 20px;
    transition: all .25s ease;
}
.bottom-navbar button:active:not(.float) {
    transform: scale(1.2);
}
.bottom-navbar button.float {
    margin-top: -50px;
    background: rgb(232, 76, 79);
    color: #fff;
    border-radius: 25px;
    height: 60px;
    box-shadow: 0px 10px 20px 0px rgba(232, 76, 79, .4);
}
.bottom-navbar button.active {
    color: rgb(232, 76, 79);
}
.bottom-navbar button i {
    font-size: 2rem;
    pointer-events: none;
}
.con-effect {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    overflow: hidden;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.effect {
    background: rgba(232, 76, 79, .15);
    width: 60px;
    height: 50px;
    position: absolute;
    left: 13px;
    border-radius: 18px;
}

.bx{
    font-size: 80px;
}