@import "https://cdn.jsdelivr.net/npm/boxicons@2.0.5/css/boxicons.min.css";


.ItemPreviewPopOutButton{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    cursor: pointer;
    /*
    padding: 1rem 1rem 1rem 20%;
    opacity: 0;
    */
    transition: .25s cubic-bezier(.5,0,.5,1);
      transition-property: all;
    transition-property: opacity;
    overflow: hidden;
    border-radius: inherit;
}